<template>
    <div class="p-relative">

        <Toolbar
            :is-list="true"
            v-on:toolbar-button-click="onToolbarClick"
        >
            <!--Панель поиска-->
            <SearchPanel
                v-model="search"
                :has-filter="true"
                @on-filter-click="onFilterClick"
            />
        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="status"
        >
            <ExtendedFilterPanel
                v-if="$helpers.isExtendedFilterPanelVisible(extendedFilter)"
                v-bind:value.sync="extendedFilter"
            />
        </FilterPanel>
        
        <v-row no-gutters>

            <v-col cols="12" xs="12" md="12">
                <v-data-table
                    :loading="loading"
                    :headers="headers"
                    :items="entities"
                    :item-class="itemRowBackground"
                    :options.sync="options"
                    :server-items-length="total"
                    :items-per-page="10"
                    :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                    :page.sync="options.page"
                    @page-count="paginationPageCount = $event"
                    class="elevation-1 customization-list-table pagination-list-table sticky-table"
                    @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Chancellery|Documents.Document' })">
                    <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                    <template v-slot:[`item.createdate`]="{ item }">
                        <div>{{ $moment(item.createdate).format('DD.MM.YYYY HH:mm:ss') }}</div>
                    </template>

                    <template v-slot:[`item.outdate`]="{ item }">
                        <div>{{ $moment(item.outdate).format('DD.MM.YYYY') }}</div>
                    </template>

                    <template v-slot:[`item.correspondent`]="{ item }">
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.correspondent">
                                {{ item.correspondent }}
                            </div>
                        </div>
                    </template>
                    
                    <template v-slot:[`item.theme`]="{ item }">
                        <div class="elipsis-text-wrap">                        
                            <v-doc-type-chip :type="item.doctypeof" :text="item.doctype"/>
                            <div class="etw-text-container">
                                <div class="etw-text" v-tooltip.top-center="item.theme">
                                    {{ item.theme }}
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`footer.prepend`]>
                        <v-pagination
                            v-model="options.page"
                            :length="paginationPageCount"
                            :total-visible="$listPaginationTotal"
                            color="teal"
                        ></v-pagination>
                    </template>

                </v-data-table>
            </v-col>

        </v-row>

        <ExFilterDlg
            v-model="extendedFilter"
            ref="ExFilterDlgRef"
        />

    </div>
</template>

<script>
import _ from "lodash";
import { httpAPI } from "@/api/httpAPI";

import Toolbar from '@/components/documents/Toolbar.vue';
import SearchPanel from '@/components/documents/SearchPanel.vue';
import FilterPanel from '@/components/documents/FilterPanel.vue';
import ExtendedFilterPanel from '@/components/documents/ExtendedFilterPanel.vue';

import ExFilterDlg from '@/components/documents/dialogs/extended-filters/chancellery/incoming.vue';

export default {
    name: "ChancelleryIncomings",
    columnIndex:  {
        'createdate': 7,
        'outnumber': 2,
        'correspondent': 5,
        'theme': 6,
        'outdate': 3,
    },
    components: {
        Toolbar,
        SearchPanel,
        FilterPanel,
        ExtendedFilterPanel,
        ExFilterDlg
    },
    computed: {
        headers() {
            return [
                { text: this.$t("Дата_поступления"), value: 'createdate', width: '150px' },
                { text: this.$t("Исх_№"), value: 'outnumber', width: '150px' },
                { text: this.$t("Дата_исходящего"), value: 'outdate', width: '140px' },
                { text: this.$t("Корреспондент"), value: 'correspondent', width: '20%' },
                { text: this.$t("Краткое_содержание"), value: 'theme'}
            ];
        },
        status: {
            get: function() {
                return this.$store.getters['chancellery/lists/incomings/getStatus']
            },
            set: function(v) {
                if (typeof this.$refs.currentComponent?.resetPage === "function")
                    this.$refs.currentComponent.resetPage();

                this.$store.commit('chancellery/lists/incomings/SET_STATUS', v );
            }
        },
        search: {
            get: function() {
                return this.$store.getters['chancellery/lists/incomings/getSearch']
            },
            set: _.debounce(function(v) {
                if (typeof this.$refs.currentComponent?.resetPage === "function")
                    this.$refs.currentComponent.resetPage();

                this.$store.commit('chancellery/lists/incomings/SET_SEARCH', v );
            }, 500)
        },
        extendedFilter: {
            get: function() {
                return this.$store.getters['chancellery/lists/incomings/getExtendedFilter']
            },
            set: function(v) {
                if (typeof this.$refs.currentComponent?.resetPage === "function")
                    this.$refs.currentComponent.resetPage();

                if (v)
                    this.$store.commit('chancellery/lists/incomings/SET_EXTENDED_FILTER', v );
            }
        },
        options: {
            get: function() {
                return this.$store.getters['chancellery/lists/incomings/getTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('chancellery/lists/incomings/SET_TABLE_OPTION', newValue);
            }
        },
        filter() {
            return {
                ...this.options,
                SearchStr: this.search,
                RegState: this.status,
                extendedFilterData: this.$helpers.getOnlySelectedParams(this.extendedFilter)
            }
        },
    },
    data() {
        return {
            collection: "Avrora.Objects.Modules.Docflow.DocflowObjects.IncomingDocument",
            loading: false,

            entities: [],
            total: 0,

            paginationPageCount: 0,

            filterDataSource: null
        }
    },
    methods: {
        itemRowBackground (item) {
            return item.readed ? '' : 'font-weight-bold';
        },
        onToolbarClick(e, btn) {
            let method = this[btn.Action];

            if (method)
                method(btn);
            else
                console.log(`${btn.Action} method not implemented!`);
        },
        async Refresh() {
            await this.loadCollectionData();
            await this.getData(true);
        },
        async onFilterClick() {
            try
            {
                await this.$refs.ExFilterDlgRef.open();
            }
            catch (ex)
            {
                if (ex.isCancelled === true)
                    console.warn("Подготовка расширенного фильтра отменена");
            }
        },
        async loadCollectionData() {
            let response = await httpAPI({
                url: `api/actions/collection?type=Chancellery.Document`,
                method: 'GET'
            });
            
            if (response) {
                this.$store.commit('documents/toolbar/SET_MENU', { menu: response.data.Payload.Data.Menu });
                await this.loadFilters();
            }
        },
        async loadFilters() {
            let filterResponse = await httpAPI({
                url: `/api/chancellary/filter?collection=${this.collection}`,
                method: 'GET'
            });

            if (filterResponse) {
                var filter = filterResponse.data.Payload.Filter.Items.find(x => x.Value == this.collection);
                this.filterDataSource = { Filter: filter?.Items }
            }
        },
        async getData(isRefresh = false) {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
                
            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.entities = [];
            this.loading = true;
            
            let response = await httpAPI({
                url: `/api/chancellary/list?filter=${JSON.stringify(this.getTableFilterObject())}`,
                method: 'GET'
            });

            this.entities = response?.data?.Payload?.data ?? [];
            this.total = response?.data?.Payload?.total ?? 0;            
            this.loading = false;
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr, RegState, extendedFilterData } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? true : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "createdate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 7;

            return { SearchStr, RegState, DocType: this.collection, OrderBy, OrderDir, Limit: itemsPerPage, Offset: (page - 1) * itemsPerPage, ...extendedFilterData };
        }
    },
    watch: {
        filter: {
            handler() {
                this.getData()
            },
            deep: true,
        },
    },
    async created () {
        await this.loadCollectionData();
    }
}
</script>